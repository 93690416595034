<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Tambah Kupon'">
          <template v-slot:body>
            <Form :form="form" :route="'coupons'" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
// import Form from '@/component/masters/coupons/Form.vue'
import Form from "@/component/masters/coupons/Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      form: {
        name: "",
        is_percent: "0",
        start_date: "",
        end_date: "",
        amount: "",
        max_amount: 0,
        description: "",
      },
    };
  },

  components: {
    Card,
    Form,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kupon", route: "" },
      { title: "Daftar Kupon", route: "/masters/coupons/list" },
      { title: "Tambah" },
    ]);
  },
};
</script>